
.filter-wrap {
  height: 34px;
  line-height: 34px;
  select {
    background: $bgColor;
  }
}

.list-top {
  .form-inline .form-control {
    background: #eee;
  }
  .input-group-btn button {
    background: #efefef;
    &:hover {
      background: #28B779;
      border-color: #ccc;
    }
  }
}