.export-wrap {
  text-align: center;
}
.img-wrap {
  margin: 70px 0 62px 0;
}
.dropdown-wrap {
  display: inline-block;
  width: 600px;
}
.allxml-wrap {
  margin-top: 17px;
  vertical-align: top;
  text-align: left;
  label {
    font-weight: 300;
    width: 33%;
  }
}
.btn.export-btn {
  width: 400px;
  height: 60px;
  margin-top: 10px;
  line-height: 40px;
  font-size: 24px;
}