.breadcrumb { 
  border-radius: 0;
  margin:-20px -20px 20px;
}
.actions-wrap {
  .tip {
    float: left;
    text-align: center;
    .fa-check-circle-o{
      color:#28b779;
    }
    :hover{
      background:#28b779;
      color: #fff;
    }
  }
}
.icon-active {
  display: inline-block;
}
.user-dialog {
  background: #f5f5f5;
  padding: 20px;
}
.user-warp {
  border: 1px solid #ddd;
  margin: 10px;
  padding-top: 20px;
  background: #F9F9F9;
  .form-footer{
    width:100%;
    margin: 0;
  }
  .form-title{
    font-size: 18px;
    color: #666;
    padding-bottom: 10px;
    margin: -5px 20px 20px;
    border-bottom: 1px solid #d0d0d0;
  }
}

.form-footer {
  border-top: 1px solid #ddd;
  background: #F5F5F5;
  padding: 20px 0;
}

.form-horizontal .btn-gray{
  background: #f5f5f5;
  color:#333;
  border: 1px solid #ddd;
}
.form-horizontal .btn-gray:hover{
  background: #eee;
  color:#333;
}
.width-full {
  width: 100%;
}
.roles-warp {
  padding: 10px 0;
  font-size: 0;
  text-align: left;
}
.role-item {
  position: relative;
  display: inline-block;
  margin: 0 10px 10px 0 ;
  height: 30px;
  line-height: 30px;
  border: 1px solid #fff;
  background: #fff;
  color:#333;
  padding:0 65px 0 10px;
  white-space:nowrap;
  min-width: 23%;
  font-size: 14px;
  text-align: left;
  font-weight: 300;
  cursor: default;
  i{
    float: right;
    width: 30px;
    text-align: center;
    position: absolute;
    right: 0;
    font-style: normal;
    font-size: 12px;
    cursor: pointer;
  }
  input{
    position: absolute;
    right: 0;
  }
}
.role-item:hover,.role-item.role-item-active{
  border: 1px solid #ddd;
}

.icon-active {
  display: inline-block;
  width: 15px;
  height: 15px;
  overflow: hidden;
  background: url(../assets/images/roles-disabled.png) no-repeat 0 0;
}
.icon-active.actived {
  background: url(../assets/images/roles-enable.png) no-repeat 0 0;
}


.bg-gray {
  background: #f6f6f6;
}
.permiss-item{
  background: #fff;
  padding: 5px 15px;
  overflow: hidden;
  border:1px solid #ddd;
  font-size: 14px;
  input[type=checkbox]{
    width: auto;
    height: auto;
    margin-top: 4px;
  }
  label{
    display: block;
  }
}

.permiss-mod {
  float: left;
  border: 1px solid #ddd;
  padding: 5px 15px;
  margin: 10px;
  background: #fff;
}
.permiss-mod-title {
  float: left;
  width: 130px;
  margin-right: 10px;
}
.permiss-mod-con {
  overflow: hidden;
}
.permiss-mod-item {
  float: left;
  margin-right: 10px;
  min-width: 100px;
  font-weight: 300;
  input[type=checkbox]{
    width: auto;
    height: auto;
    vertical-align: -2px;
  }
}
.mb-n{
  margin-bottom: 10px;
}
.table-role>thead>tr>th{
  padding: 2px 0 3px 2px;
}
.table-role>tbody>tr>td{
  padding: 2px 0 3px 20px;
  vertical-align: middle;
  word-break: break-all;
}

.table-role>tbody>tr>td:nth-child(2){
  position: relative;
}
.role-name{
  position: relative;
  top: -3px;
  left:-21px;
  width:calc( 100% + 22px );
  height:41px;
  margin-bottom: -7px;
}
.editTd{
  z-index: 1000;
  border: 1px solid $btnColor!important;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(40, 183, 121, .6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(40, 183, 121, .6);
}
.dirty-pos {
  position: absolute;
  right:10px;
  top:5px;
  z-index: 1001;
}

.submitted, .add-trans-btn{
  float: right;
}
.action-icon {
  width: 30px;
  height: 33px;
  line-height: 33px;
}