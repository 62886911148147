.login{
	text-align: center;
	margin-top: 130px;
	margin-left: -200px;
}
.body-login{
  background: #1f262d;
}
.login-content{
	display: inline-block;
	width: 546px;
}
.login-input {
	height: 40px;
	margin: 40px 20px;
}
.login-logo{
  height: 120px;
  background: url(../assets/images/logo.png) no-repeat 58% center;
}
.login-btn{
  width: 546px;
  line-height: 30px;
  font-size: 16px;
  margin: 20px 0 0 20px;
}
.section-title {
    margin-bottom: 56px;
}