@mixin global-reset {
	html, body, div, span, applet, object, iframe,
	h1, h2, h3, h4, h5, h6, p, blockquote, pre,
	a, abbr, acronym, address, big, cite, code,
	del, dfn, em, img, ins, kbd, q, s, samp,
	small, strike, strong, sub, sup, tt, var,
	b, u, i, center,
	dl, dt, dd, ol, ul, li,
	fieldset, form, label, legend,
	table, caption, tbody, tfoot, thead, tr, th, td,
	article, aside, canvas, details, embed, 
	figure, figcaption, footer, header, hgroup, 
	menu, nav, output, ruby, section, summary,
	time, mark, audio, video {
		@include reset-box-model;
	}
	// Unlike Eric's original reset, we reset the html element to be compatible
	// with the vertical rhythm mixins.
	ol, ul {
		@include reset-list-style;
	}
	a img {
		@include reset-image-anchor-border;
	}
}


// Reset the box model measurements.
@mixin reset-box-model {
	margin: 0;
	padding: 0;
	border: 0;
}

// Resets the outline when focus.
// For accessibility you need to apply some styling in its place.
@mixin reset-focus {
	outline: 0;
}

// Reset the list style of an element.
@mixin reset-list-style {
	list-style: none;
}

// Resets the border.
@mixin reset-image-anchor-border {
	border: none;
}