.app-header{
	line-height: $headerHeight;
    height: $headerHeight;
	background: #1f262d;
	color: #fff;
	margin: 0;
	padding-left: 33px;
}
.app-header .title{
  height: $headerHeight;
  padding: 15px 0;
  width: 124px;
  font-size: 24px;
  line-height: $headerHeight;
  float:left;
  margin: 0;
  background: url(../assets/images/logo.png) no-repeat left center;
  background-size: 124px;
  a {
    display: block;
    height: 30px;

  }
}
.login-user{
	float: right;
  color: #666;
  margin-right: 35px;
}
.log-out-btn{
  display: inline-block;
  width: 80px;
  color: #666;

  i{
    display: inline-block;
    width: 24px;
    height: 26px;
    background: url(../assets/images/nav_pic.png) no-repeat 6px -278px;
    position: relative;
    top: 3px;
  }

  &:hover{
    color: white;
    background:#000;
    i{
    background: url(../assets/images/nav_pic.png) no-repeat -24px -278px;
    }
  }
}
.feedback-btn{
  display: inline-block;
  width: 120px;
  color: #666;
  //margin-right: 16px;
  i{
    margin: 0 10px 0 16px;
    display: inline-block;
    width: 14px;
    height: 14px;
    background: url(../assets/images/feedback-icon.png) no-repeat center;
    position: relative;
    top: 3px;
  }

  &:hover{
    color: white;
    background:#000;
    i{
    background: url(../assets/images/feedback-icon-hover.png) no-repeat center;
    }
  }
}

.topnav{
  float: left;
  font-size: 0;
  height:$headerHeight ;
  overflow: hidden;
  font-family:"Classic Grotesque W01","Hiragino Sans GB","STHeiti","Microsoft YaHei","WenQuanYi Micro Hei",Arial,SimSun,sans-serif;
  font-weight: 700;
  margin-left: 72px;
  li {
    display: inline-block;
    padding: 0 12px;
    a {
      display: inline-block;
      font-size: 16px;
      padding: 0 12px;
      color:#807f79;
      height: $headerHeight;
      line-height: $headerHeight;
      &:hover, &.active{
        color:#28b779;
        border-bottom: 5px solid #28b779;
      }
    }
  }
}