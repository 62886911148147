.list-top{
  margin: 10px 0;
}
.list-middle{
  padding: 20px 6px;
  border: 1px solid #ddd;
  border-bottom: none;
  background: #fff;
}
.list-total{
  float: right;
  padding: 28px;
  padding-right: 10px;
}
.list-total-checked{
  line-height: 30px;
}
.table-footer-btn-wrap{
  padding-top: 21px;
}

