.progress{
  margin-bottom: 0;
  border-radius: 0;
  height:16px;
}
.progress-bar-success{
  background-color: #23B472;
}
.progress-bar-failed{
  background-color: #9e9e9e;
}